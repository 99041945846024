import { useSearchParams } from '@remix-run/react'
import { type RemixFormProps } from '@remix-run/react/dist/components'
import { type ReactNode, useEffect, useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { Button } from '#app/components/ui/button'
import { Input } from '#app/components/ui/input'
import { cn } from '#app/utils/misc'

type SearchQueryProps = {
	className?: string
	hideButton?: boolean
	children?: ReactNode
	autoFocus?: boolean
} & Pick<RemixFormProps, 'action' | 'method'> &
	Pick<HTMLInputElement, 'name' | 'placeholder'>

export function SearchQuery(props: SearchQueryProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [query, setQuery] = useState(searchParams.get('q') ?? '')
	const [debouncedQuery, setDebouncedQuery] = useDebounceValue('', 500)

	useEffect(() => {
		if (debouncedQuery !== searchParams.get('q')) {
			if (debouncedQuery.length === 0) {
				setSearchParams((prev) => {
					prev.delete('q')
					return prev
				})
			} else {
				setSearchParams({ q: debouncedQuery })
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedQuery])

	// track the search query
	useEffect(() => {
		if (query !== searchParams.get('q')) {
			setQuery(searchParams.get('q') ?? '')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	return (
		<div
			className={cn(
				'flex flex-none gap-x-2 border-b border-border px-3 py-3',
				props.className,
			)}
		>
			<Input
				name={props.name}
				type="text"
				autoFocus={props.autoFocus}
				value={query}
				onChange={(e) => {
					setQuery(e.target.value)
					setDebouncedQuery(e.target.value)
				}}
				placeholder={props.placeholder}
				className="flex-grow"
			/>
			{!props.hideButton && (
				<Button
					variant={'outline'}
					onClick={() => setSearchParams({ q: query })}
					className="hover:bg-white"
				>
					Search
				</Button>
			)}
			{props.children}
		</div>
	)
}
